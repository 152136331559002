<template>
  <div
    @click="selectOrUnselect"
  >
    <ChoiceText
      :item="item"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Choice',
  props: {
    item: Object,
  },
  components: {
    ChoiceText: lazyLoad('components/Cards/Choice/ChoiceText'),
  },
  computed: {
    ...mapGetters('testMacro', [
      'textChoiceSelection',
    ]),
    ...mapGetters('testMicro', [
      'id',
      'activeStep',
      'selectedIndex',
    ]),
    choiceText() {
      return this.textChoiceSelection[this.id].includes(this.activeStep);
    },
  },
  methods: {
    ...mapActions('testMicro', [
      'resetSelection',
      'setSelection',
    ]),
    selectOrUnselect() {
      if (this.item.index === this.selectedIndex) {
        this.resetSelection();
      } else {
        this.setSelection(this.item.index);
      }
    },
  },
};
</script>
